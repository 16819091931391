<template>
  <v-content class="loginContent">
    <v-container class="fill-height"
                 fluid>
      <v-row align="center"
             justify="center">
        <v-col cols="12"
               sm="8"
               md="4">
          <v-card class="elevation-12">
            <v-toolbar color="#213B87"
                       dark
                       flat>
              <v-toolbar-title>
                <router-link to="/">
                  <img src="../assets/images/WEB-BOT-EROVET.png" width="175" alt="logoerovet">
                </router-link>
              </v-toolbar-title>
              <v-spacer/>
            </v-toolbar>
            <v-card-text>
              <v-alert
                  border="right"
                  colored-border
                  type="error"
                  v-if="emptyError"
                  elevation="2"
              >
                New passwords not match
              </v-alert>
              <v-alert
                  border="right"
                  colored-border
                  type="error"
                  v-if="ko"
                  elevation="2"
              >
                There was an error, please try again later
              </v-alert>
              <v-form @keyup.enter.native="submit">
                <v-text-field
                    v-model="password"
                    id="password"
                    label="Set new password"
                    name="password"
                    prepend-icon="lock"
                    type="password"/>
                <v-text-field
                    v-model="password2"
                    id="password"
                    label="Repeat new password"
                    name="password"
                    prepend-icon="lock"
                    type="password"/>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn color="#213B87" class="loginBT" :loading="loading" v-on:click="submit">Set new password</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
export default {
  name: "Renew",
  data() {
    return {
      loading: false,
      emptyError: false,
      ko:false,
      apiError: false,
      notFoundError: false,
      email: '',
      password: '',
      password2: ''
    }
  },
  methods: {
    submit() {
      this.loading = true;
      this.ko = false;
      if (this.password !== this.password2) {
        this.emptyError = true;
        this.loading = false;
      } else {
        this.emptyError = false;
        let url = this.base_url + '/home/reset';
        let formData = new FormData();
        formData.set('password', this.password);
        formData.set('token',this.$route.params.token)
        this.axios.post(url, formData).then(() => {
          this.loading = false;
          this.$router.push('/login')
        }).catch(()=>{
          this.loading = false;
          this.ko = true;
        })
      }
    }
  }
};
</script>
<style>
.loginBT {
  font-size: 12pt !important;
  margin: 0 0.5em 0 0.5em;
  padding: 0.6em !important;
}

.emptyAlert {
  background: #9D130B !important;
}
</style>
